<template>
  <div class="w-full">
    <div>
      <transition name="list">
        <div :style="'margin-left: ' + this.depth * 20 + 'px'">
          <div v-if="depth > 0" class="w-full">
            <vx-card class="my-2 card-plano-conta">
              <vs-row
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <vs-col
                  vs-w="10"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                >
                  <div @click="showChildren = !showChildren">
                    <span
                      style="color: black; font-size: 16px"
                      :style="depth == 1 ? 'font-weight:bold' : ''"
                      ><vs-icon
                        :icon="
                          showChildren ? 'arrow_drop_down' : 'arrow_drop_up'
                        "
                        size="20px"
                      ></vs-icon
                      >{{ nome }}
                    </span>
                  </div>
                </vs-col>
                <vs-col
                  vs-w="2"
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="flex-end"
                >
                  <div class="w-full">
                    <vs-row
                      vs-w="12"
                      vs-type="flex"
                      vs-justify="flex-end"
                      vs-align="flex-end"
                    >
                      <vs-col
                        vs-w="6"
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="flex-end"
                      >
                        <ModalMetas :planoConta="plano" />
                      </vs-col>
                      <vs-col
                        vs-w="6"
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="flex-end"
                      >
                        <Editar :planoConta="plano" />
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-col>
              </vs-row>
            </vx-card>
          </div>
        </div>
      </transition>
    </div>
    <Plano
      v-show="showChildren"
      v-for="plano in planoContas"
      :key="plano.id"
      :codigo="plano.codigo"
      :idPlano="plano.id"
      :plano="plano"
      :planoContas="plano.child"
      :nome="plano.nome"
      :depth="depth + 1"
    ></Plano>
  </div>
</template>

<script>
import ModalMetas from "./modalMetas"
import Editar from "./editar"
export default {
  props: {
    nome: null,
    plano: null,
    planoContas: null,
    codigo: null,
    depth: null,
    idPlano: null,
    isShowChilds: false
  },
  data () {
    return { showChildren: true }
  },
  name: "Plano",
  computed: {
    indent () {
      return { transform: `translate(${this.depth * 20}px)` }
    }
  },
  methods: {
    // toggleChildren() {
    //   this.showChildren = !this.showChildren;
    // },
    // async getAllChildren() {
    //   this.$vs.loading();
    //   try {
    //     let children = await this.$http.post(`getAllChildrenPlano`, {id: this.idPlano});
    //   } catch (err) {
    //     const error = this.$httpErrors(err);
    //     this.$vs.notify(error);
    //   } finally {
    //     this.$vs.loading.close();
    //   }
    // }
  },
  components: {
    ModalMetas,
    Editar
  }
};
</script>

<style lang="scss">
.actions {
  width: 60px !important;
}
.card-plano-conta {
  background-color: #ffffff;
  transition-duration: 80ms;
  &:hover {
    transition-duration: 80ms;
    transform: translateY(-2.5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s;
}
.list-enter, .list-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
