<template>
  <div>
    <vs-button
      @click="openModal()"
      class="py-1 px-2"
      style="font-size: 13px"
      color="success"
      >METAS</vs-button
    >

    <vs-popup
      :title="'Metas: ' + this.planoConta.nome"
      :active.sync="modalActive"
    >
      <div class="w-full vs-con-loading__container" id="modalMetas">
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Janeiro"
                v-model="planoConta.meta_janeiro"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Fevereiro"
                v-model="planoConta.meta_fevereiro"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Março"
                v-model="planoConta.meta_marco"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Abril"
                v-model="planoConta.meta_abril"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                class="w-full"
                label="Maio"
                v-model="planoConta.meta_maio"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                label="Junho"
                v-model="planoConta.meta_junho"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                label="Julho"
                v-model="planoConta.meta_julho"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                label="Agosto"
                v-model="planoConta.meta_agosto"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                label="Setembro"
                v-model="planoConta.meta_setembro"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                label="Outubro"
                v-model="planoConta.meta_outubro"
                icon-after="true"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                label="Novembro"
                v-model="planoConta.meta_novembro"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
          <vs-col
            vs-w="4"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
          >
            <vx-input-group class="mb-5 mt-0 w-full mx-2">
              <vs-input
                label="Dezembro"
                v-model="planoConta.meta_dezembro"
                type="number"
                color="dark"
                min="0"
              />
            </vx-input-group>
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
          <vs-button
            class="mx-2 w-full"
            color="success"
            type="relief"
            @click="setGoals()"
            >Atualizar</vs-button
          >
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    planoConta: null
  },
  data () {
    return {
      modalActive: false
    }
  },
  methods: {
    async openModal () {
      this.modalActive = true
    },
    async setGoals () {
      await this.$vs.loading({
        container: "#modalMetas",
        scale: 0.6
      })
      try {
        let planoConta = this.planoConta
        delete planoConta.child
        await this.$http.put(
          `plano_conta/${this.planoConta.id}`,
          this.planoConta
        )
        await this.$vs.notify(this.$notify.Success)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#modalMetas > .con-vs-loading")
        this.modalActive = await false
      }
    }
  },
  components: {
    ...components
  }
};
</script>
