<template>
  <div>
    <EditButton @func="openModal()"></EditButton>
    <SideBar
      v-if="open"
      title="Editar plano de conta"
      @hide="cancelar"
      :active="modalEditarPlanoConta"
      :item="planoConta"
      textSucces="Atualizar"
      @excluir="excluirPlanoConta"
      @status="mudarStatusPlanoConta"
      @success="submitForm()"
      size="small"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12" vs-type="flex">
            <div class="w-full">
              <vs-row vs-w="12" vs-type="flex">
                <vs-col vs-w="12" vs-type="flex">
                  <vs-input
                    class="inputx w-full mx-1 mt-2"
                    name="nomePlanoConta"
                    v-validate="'required'"
                    label="Nome"
                    v-model="planoConta.nome"
                  />
                </vs-col>
                <vs-col vs-w="12" vs-type="flex">
                  <span
                    class="text-danger text-sm mx-2"
                    v-show="errors.has('nomePlanoConta')"
                    >{{ $validators.empty }}</span
                  >
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mb-2" vs-type="flex" vs-w="12">
          <vs-col vs-w="12" vs-type="flex">
            <div class="w-full">
              <vs-row vs-w="12" vs-type="flex">
                <vs-col vs-w="12" vs-type="flex">
                  <div class="w-full mt-2">
                    <label style="color: black; font-size: 0.85rem" class="pl-2"
                      >Plano Pai</label
                    >
                    <el-select
                      filterable
                      clearable
                      remote
                      :remote-method="filtroPlanoConta"
                      name="planoConta"
                      :loading="loading"
                      v-validate="'required'"
                      placeholder="Pesquise"
                      loading-text="Buscando..."
                      class="w-full mx-1"
                      v-model="planoConta.id_parent"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="data in plano_conta"
                        :value="data.id"
                        :label="data.nome"
                        :key="data.id"
                      ></el-option>
                    </el-select>
                  </div>
                </vs-col>
                <vs-col vs-w="12" vs-type="flex">
                  <span
                    class="text-danger text-sm mx-2"
                    v-show="errors.has('planoConta')"
                    >{{ $validators.empty }}</span
                  >
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  name: "modalEditarPlanoConta",
  props: { planoConta: Object },
  data () {
    return {
      modalEditarPlanoConta: false,
      error: false,
      originalData: {},
      plano_conta: [],
      loading: false,
      open: false
    }
  },
  methods: {
    async openModal () {
      this.open = true
      this.modalEditarPlanoConta = true
      this._beforeEditingCache = Object.assign({}, this.planoConta)
      this.originalData = this.planoConta
      await this.getPlanoConta()
    },
    async submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarPlanoConta()
        } else {
          this.$vs.notify({
            title: "Preencha todos os campos",
            text: "Os campos não devem ser vazios!",
            color: "danger"
          })
        }
      })
    },
    async filtroPlanoConta (query) {
      this.loading = true
      if (query.length > 2) {
        try {
          await this.getPlanoContaByQuery(query)
        } catch (err) {
          const error = this.$httpErrors(err)
          this.$vs.notify(error)
        }
      }
      this.loading = false
    },
    async getPlanoConta () {
      try {
        const res = await this.$http.get(`plano_conta/${this.planoConta.id_parent}`)
        this.plano_conta = [res]
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async getPlanoContaByQuery (query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
    async editarPlanoConta () {
      this.$vs.loading()
      try {
        const res = await this.$http.put(
          `plano_conta/${this.planoConta.id}`,
          this.planoConta
        )
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Edição do plano de conta N°' + res.id
        }
        await this.$logger(logData)
        this.modalEditarPlanoConta = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    },
    async excluirPlanoConta () {
      this.$vs.loading()
      try {
        await this.$http.delete(`plano_conta/${this.planoConta.id}`)
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'excluir',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Exclusão do plano de conta N°' + this.planoConta.id
        }
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success)
        this.modalEditarPlanoConta = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
        this.$emit("update")
      }
    },
    async mudarStatusPlanoConta () {
      this.$vs.loading()
      try {
        const res = await this.$http.put(`plano_conta/${this.planoConta.id}`, {
          lixeira: !this.planoConta.lixeira
        })
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: 'atualizar',
          setor: 'comercial',
          ip: window.localStorage.getItem("ip"),
          texto: 'Mudança do status do plano de conta N°' + res.id
        }
        await this.$logger(logData)
        this.$vs.notify(this.$notify.Success)
        this.modalEditarPlanoConta = false
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
        this.$emit("update")
      }
    },
    cancelar () {
      Object.assign(this.planoConta, this._beforeEditingCache)
      this.originalData = this._beforeEditingCache = null
      this.modalEditarPlanoConta = false
    }
  },
  components: components
};
</script>

<style></style>
